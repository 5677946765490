<template>
  <div class="content_body SaleProjectCommissionScheme" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="组织单位">
              <el-input
                @clear="handleSearch"
                v-model="Name"
                placeholder="输入组织单位名称搜索"
                clearable
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="saleProjectTableData">
        <el-table-column prop="EntityName" label="组织单位"></el-table-column>
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="removeEntityClick(scope.row)"
              v-prevent-click
              :loading="modalLoading"
              v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10 text_right">
        <div class="text_right" v-if="paginations.total > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paginations.page"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!--新增弹窗-->
    <el-dialog
      title="新增项目销售提成方案"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="组织单位" prop="EntityID">
            <span slot="label">
              适用组织
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需选择父节点。</p>
                <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                <p>
                  如需要设置经手人/职务提成，请选择已经配置经手人的组织单位。
                </p>
                <el-button
                  type="text"
                  style="color: #dcdfe6"
                  icon="el-icon-info"
                  slot="reference"
                ></el-button>
              </el-popover>
            </span>
            <treeselect
              v-model="ruleForm.EntityID"
              :options="entityList"
              :normalizer="normalizer"
              clearValueText
              noResultsText="无匹配数据"
              placeholder="选择所属部门"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProjectCommissionClick"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <!--编辑弹窗-->
    <el-dialog :visible.sync="dialogEdit" width="70%">
      <div slot="title">{{ entityName }} - 项目分类销售提成方案</div>
      <el-table
        size="small"
        :data="saleProjectCategoryTableData"
        row-key="CategoryID"
        :tree-props="{ children: 'Child', hasChildren: 'hasChild' }"
        :row-class-name="tableRowClassName"
        max-height="500px"
      >
        <el-table-column
          prop="CategoryName"
          label="项目分类"
          min-width="150px"
          fixed
        ></el-table-column>
        <el-table-column label="现金比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayRate"
              v-enter-number
              type="number"
              min="0"
              class="input_type"
              @input="royaltyRateChange(1, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="现金固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayFixed"
              v-enter-number2
              type="number"
              min="0"
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="卡抵扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardRate"
              v-enter-number
              type="number"
              min="0"
              class="input_type"
              @input="royaltyRateChange(2, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="卡抵扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardFixed"
              v-enter-number2
              type="number"
              min="0"
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessRate"
              v-enter-number
              type="number"
              min="0"
              class="input_type"
              @input="royaltyRateChange(3, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessFixed"
              v-enter-number2
              type="number"
              min="0"
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="特殊补贴" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SpecialBenefit"
              v-enter-number2
              type="number"
              min="0"
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="255px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handerCommissionAll(0, scope.row)"
              v-if="scope.row.isEntity && !scope.row.isProjectCommission"
              >所有项目经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="handerCommission(scope.row)"
              v-if="!scope.row.isEntity"
              >分类经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="projectCommission(scope.row)"
              v-if="!scope.row.isProjectCommission && !scope.row.isEntity"
              >项目提成</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEdit = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProjectCategoryClick"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <!--经手人弹窗-->
    <el-dialog :visible.sync="dialogHander" width="60%">
      <div slot="title" v-if="whichHandlerDialog != 1">
        {{ entityName }} - {{ categoryName }} - 经手人/职务销售提成方案
      </div>
      <div slot="title" v-else>
        {{ entityName }} - {{ categoryName }} - {{ projectName }} -
        经手人/职务销售提成方案
      </div>
      <el-tabs v-model="handleActiveName" >
        <el-tab-pane label="项目" name="0">
          <el-table
            :data="handlerList"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasChildren',
            }"
            size="small"
            max-height="500px"
          >
            <el-table-column
              prop="Name"
              label="经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayRate"
                  type="number"
                  min="0"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(1, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayFixed"
                  type="number"
                  min="0"
                  class="input_type"
                  v-enter-number2
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡抵扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardRate"
                  type="number"
                  min="0"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(2, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡抵扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardFixed"
                  type="number"
                  min="0"
                  v-enter-number2
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessRate"
                  type="number"
                  min="0"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(3, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessFixed"
                  type="number"
                  min="0"
                  v-enter-number2
                  class="input_type"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="特殊补贴" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SpecialBenefit"
                  type="number"
                  min="0"
                  v-enter-number2
                  class="input_type"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="套餐卡-项目" name="1">
          <el-table
            :data="packageHanderCommission"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasChildren',
            }"
            size="small"
            max-height="500px"
          >
            <el-table-column
              prop="Name"
              label="经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayRate"
                  type="number"
                  min="0"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(1, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayFixed"
                  type="number"
                  min="0"
                  class="input_type"
                  v-enter-number2
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡抵扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardRate"
                  type="number"
                  min="0"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(2, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡抵扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardFixed"
                  type="number"
                  min="0"
                  v-enter-number2
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessRate"
                  type="number"
                  min="0"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(3, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessFixed"
                  type="number"
                  min="0"
                  v-enter-number2
                  class="input_type"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="特殊补贴" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SpecialBenefit"
                  type="number"
                  min="0"
                  v-enter-number2
                  class="input_type"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogHander = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitClickHandler"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <!--项目提成弹窗-->
    <el-dialog :visible.sync="dialogProject" width="65%">
      <div slot="title">
        {{ entityName }} - {{ categoryName }} - 项目销售提成方案
      </div>
      <div>
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="SearchKey"
              placeholder="输入项目名称搜索"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          :data="
            projectRoyaltyList.filter(
              (data) =>
                !SearchKey ||
                data.ProjectName.toLowerCase().includes(SearchKey.toLowerCase())
            )
          "
          row-key="ProjectID"
          size="small"
          max-height="500px"
        >
          <el-table-column
            prop="ProjectName"
            label="项目名称"
            min-width="150px"
            fixed
          ></el-table-column>
          <el-table-column label="现金比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.PayRate"
                type="number"
                min="0"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(1, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="现金固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.PayFixed"
                min="0"
                type="number"
                v-enter-number2
                class="input_type"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="卡抵扣比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardRate"
                min="0"
                v-enter-number
                class="input_type"
                type="number"
                @input="royaltyRateChange(2, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="卡抵扣固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardFixed"
                min="0"
                type="number"
                v-enter-number2
                class="input_type"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="赠送卡扣比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardLargessRate"
                min="0"
                v-enter-number
                class="input_type"
                type="number"
                @input="royaltyRateChange(3, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="赠送卡扣固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardLargessFixed"
                min="0"
                v-enter-number2
                class="input_type"
                type="number"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="特殊补贴" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SpecialBenefit"
                min="0"
                v-enter-number2
                class="input_type"
                type="number"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="115px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="clickProjectHandlerCommission(scope.row)"
                >经手人提成</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogProject = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="updateProjectCommission"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/HanderCommission/saleProjectCommissionScheme";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from "@/components/js/permission.js";

var Enumerable = require("linq");

export default {
  components: { Treeselect },
  name: "SaleProjectCommissionScheme",
  data() {
    return {
      isDelete:false,
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogEdit: false,
      dialogHander: false,
      dialogProject: false,
      Name: "",
      entityList: [],
      saleProjectTableData: [],
      entityName: "",
      categoryName: "",
      projectName: "",
      EntityID: "", //  当前删除的项目ID
      saleProjectCategoryTableData: [],
      handlerList: [], //  经手人数据
      packageHanderCommission:[], // 套餐卡经手人数据
      handlerCategoryID: "", //  经手人分类ID
      projectCategoryID: "", //  项目分类ID
      projectRoyaltyList: [], //  项目提成列表
      ProjectID: "", //  项目ID
      whichHandlerDialog: "", //  判断经手人弹框类别
      SearchKey: "", //  项目提成设置弹框搜索框值
      ruleForm: {
        EntityID: null,
      },
      rules: {
        EntityID: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      handleActiveName:"0",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-HanderCommission-SaleProjectCommissionScheme-Delete"
      );
    });
  },
  methods: {
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.getProjectCommissionScheme();
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getProjectCommissionScheme();
    },
    // 获取项目销售组织单位提成方案列表
    getProjectCommissionScheme: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.Name,
      };
      API.getProjectCommissionScheme(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.saleProjectTableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm = {
        entity: null,
      };
      that.dialogVisible = true;
    },
    // 新增提交
    submitProjectCommissionClick: function () {
      var that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          var params = {
            EntityID: that.ruleForm.EntityID,
          };
          API.createProjectCommissionScheme(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("新增成功");
                that.dialogVisible = false;
                that.getProjectCommissionScheme();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        }
      });
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.EntityID = row.EntityID;
      that.entityName = row.EntityName;
      that.getProjectCategoryCommission();
      that.dialogEdit = true;
    },
    // 获取项目销售分类提佣方案
    getProjectCategoryCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getProjectCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            var data = {
              EntityID: res.Data.EntityID,
              CategoryID: "0" + res.Data.EntityID,
              CategoryName: "所有项目",
              PayFixed: res.Data.PayFixed,
              PayRate: res.Data.PayRate,
              SavingCardFixed: res.Data.SavingCardFixed,
              SavingCardLargessFixed: res.Data.SavingCardLargessFixed,
              SavingCardLargessRate: res.Data.SavingCardLargessRate,
              SavingCardRate: res.Data.SavingCardRate,
              SpecialBenefit: res.Data.SpecialBenefit,
              isEntity: true,
              isProjectCommission: false,
            };
            var Category = Enumerable.from(res.Data.Category)
              .select((val) => ({
                CategoryID: val.CategoryID,
                CategoryName: val.CategoryName,
                PayFixed: val.PayFixed,
                PayRate: val.PayRate,
                SavingCardFixed: val.SavingCardFixed,
                SavingCardLargessFixed: val.SavingCardLargessFixed,
                SavingCardLargessRate: val.SavingCardLargessRate,
                SavingCardRate: val.SavingCardRate,
                SpecialBenefit: val.SpecialBenefit,
                isProjectCommission: true,
                Child: val.Child,
              }))
              .toArray();
              
            that.saleProjectCategoryTableData = Object.assign([], Category);
            that.saleProjectCategoryTableData.unshift(data);


          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 删除项目销售提成方案
    removeEntityClick: function (row) {
      var that = this;
      that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.modalLoading = false;
          var params = {
            EntityID: row.EntityID,
          };
          API.deleteProjectCommissionScheme(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("删除成功");
                that.getProjectCommissionScheme();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 经手人提成
    handerCommission: function (row) {
      var that = this;
      that.handleActiveName = "0"
      that.handlerList = [];
      that.handlerCategoryID = row.CategoryID;
      that.whichHandlerDialog = 0;
      that.categoryName = row.CategoryName;
      that.getProjectCategoryHandlerCommission();
      that.PackageSaleProjectCategoryHandlerCommission();
      that.dialogHander = true;
    },
    // 获取项目销售分类经手人提佣方案
    getProjectCategoryHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.handlerCategoryID,
      };
      API.getProjectCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取套餐卡- 项目销售分类经手人提佣方案
    PackageSaleProjectCategoryHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.handlerCategoryID,
      };
      API.getPackageSaleProjectCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 项目弹框经手人提成点击事件
    clickProjectHandlerCommission(row) {
      var that = this;
      that.handleActiveName = "0"
      that.handlerList = [];
      that.dialogHander = true;
      that.ProjectID = row.ProjectID;
      that.whichHandlerDialog = 1;
      that.projectName = row.ProjectName;
      that.getProjectHanderCommission();
      that.PackageSaleProjectHandlerCommission()
    },
    // 获取项目下经手人提佣方案
    getProjectHanderCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        ProjectID: that.ProjectID,
      };
      API.getProjectHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 获取项目下经手人提佣方案
    PackageSaleProjectHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        ProjectID: that.ProjectID,
      };
      API.getPackageSaleProjectHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 所有经手人提成
    handerCommissionAll: function (type, row) {
      var that = this;
      that.handleActiveName = "0"
      that.handlerList = [];
      that.handlerCategoryID = row.CategoryID;
      that.whichHandlerDialog = 2;
      that.categoryName = row.CategoryName;
      that.ProductSchemeHanderCommissionData();
      that.PackageSaleProjectSchemeHandlerCommissionData();
      that.dialogHander = true;
    },
    // 获取所有产品经手人提佣
    ProductSchemeHanderCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getProjectSchemeHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 获取所有套餐卡 产品经手人提佣
    PackageSaleProjectSchemeHandlerCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getPackageSaleProjectSchemeHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 项目提成设置
    projectCommission: function (row) {
      var that = this;
      that.projectCategoryID = row.CategoryID;
      that.categoryName = row.CategoryName;
      that.getProjectCommission();
      that.dialogProject = true;
    },
    // 获取分类下的项目提佣方案
    getProjectCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.projectCategoryID,
      };
      API.getProjectCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectRoyaltyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    
    // 保存项目销售分类提成方案
    submitProjectCategoryClick() {
      var that = this;
      var Category = that.saleProjectCategoryTableData;
      Category = Enumerable.from(Category)
        .where(function (i) {
          return !i.isEntity;
        })
        .toArray();

      Category.forEach(function (item) {
        item.Child = Enumerable.from(item.Child)
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });

      Category = Enumerable.from(Category)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.Child.length > 0
          );
        })
        .toArray();

      var params = {
        EntityID: that.saleProjectCategoryTableData[0].EntityID,
        PayFixed: that.saleProjectCategoryTableData[0].PayFixed,
        PayRate: that.saleProjectCategoryTableData[0].PayRate,
        SavingCardFixed: that.saleProjectCategoryTableData[0].SavingCardFixed,
        SavingCardRate: that.saleProjectCategoryTableData[0].SavingCardRate,
        SavingCardLargessFixed:
          that.saleProjectCategoryTableData[0].SavingCardLargessFixed,
        SavingCardLargessRate:
          that.saleProjectCategoryTableData[0].SavingCardLargessRate,
        SpecialBenefit: that.saleProjectCategoryTableData[0].SpecialBenefit,
        Category: Category,
      };

      that.modalLoading = true;
      API.updateProjectCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogEdit = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 项目提成设置弹框保存事件
    updateProjectCommission() {
      var that = this;
      var projectRoyaltyList = Enumerable.from(that.projectRoyaltyList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        Project: projectRoyaltyList,
        CategoryID: that.projectCategoryID,
      };
      API.updateProjectCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogProject = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 经手人弹框保存按钮点击事件
    submitClickHandler() {
      var that = this;
      if (that.whichHandlerDialog == 0) {
        that.updateProjectCategoryHandlerCommission();
      } else if (that.whichHandlerDialog == 1) {
        that.updateProjectHanderCommission();
      } else {
        that.ProjectSchemeHanderCommissionClick();
      }
    },
    // 保存项目销售分类经手人提成方案
    updateProjectCategoryHandlerCommission: function () {
      var that = this;
      var handlerList = that.handlerList;
      handlerList.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      handlerList = Enumerable.from(handlerList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      var packageHanderCommission = that.packageHanderCommission;
      packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      packageHanderCommission = Enumerable.from(packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.handlerCategoryID,
        ProjectHandler: handlerList,
        PackageCardProjectHandler: packageHanderCommission,
      };
      API.updateProjectCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogHander = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存项目下经手人提成方案
    updateProjectHanderCommission: function () {
      var that = this;
      var handlerList = that.handlerList;
      handlerList.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      handlerList = Enumerable.from(handlerList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      var packageHanderCommission = that.packageHanderCommission;
      packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      packageHanderCommission = Enumerable.from(packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        ProjectID: that.ProjectID,
        ProjectHandler: handlerList,
        PackageCardProjectHandler: packageHanderCommission,
      };
      API.updateProjectHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogHander = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存所有经手人提成方案
    ProjectSchemeHanderCommissionClick: function () {
      var that = this;
      var handlerList = that.handlerList;
      handlerList.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      handlerList = Enumerable.from(handlerList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      var packageHanderCommission = that.packageHanderCommission;
      packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      packageHanderCommission = Enumerable.from(packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();


      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        ProjectID: that.ProjectID,
        ProjectHandler: handlerList,
        PackageCardProjectHandler: packageHanderCommission,
      };
      API.updateProjectSchemeHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogHander = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 约束提成比例
    royaltyRateChange: function (index, row) {
      if (index == 1) {
        if (row.PayRate > 100) {
          row.PayRate = 100;
        }
      } else if (index == 2) {
        if (row.SavingCardRate > 100) {
          row.SavingCardRate = 100;
        }
      } else if (index == 3) {
        if (row.SavingCardLargessRate > 100) {
          row.SavingCardLargessRate = 100;
        }
      }
    },
    // 高亮第一级表格
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "info-row";
      }
      return "";
    },
    // 树形结构数据转换
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
  },
  mounted() {
    var that = this;

    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-HanderCommission-SaleProjectCommissionScheme-Delete"
    );
    that.handleSearch();
    that.entityData();
  },
};
</script>

<style lang="scss">
.SaleProjectCommissionScheme{

  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-table .info-row {
    background: #c0c4cc;
  }
  .el-input__inner {
    padding-right: 0;
  }
}
</style>
