/**
 * Created by jerry on 2020/03/30.
 * 项目销售提佣 api
 */
import * as API from '@/api/index'

export default {
    // 获取项目销售组织单位提成方案列表
    getProjectCommissionScheme: params => {
        return API.POST('api/saleProjectCommissionScheme/list', params)
    },
    // 创建项目销售组织单位提成方案
    createProjectCommissionScheme: params => {
        return API.POST('api/saleProjectCommissionScheme/create', params)
    },
    // 删除项目销售提成方案
    deleteProjectCommissionScheme: params => {
        return API.POST('api/saleProjectCommissionScheme/delete', params)
    },
    // 获取项目销售分类提佣方案
    getProjectCategoryCommission: params => {
        return API.POST('api/saleProjectCategoryCommission/all', params)
    },
    // 保存项目销售分类提成方案
    updateProjectCategoryCommission: params => {
        return API.POST('api/saleProjectCategoryCommission/update', params)
    },
    // 获取项目销售分类经手人提佣方案
    getProjectCategoryHandlerCommission: params => {
        return API.POST('api/saleProjectCategoryHandlerCommission/all', params)
    },
    // 保存项目销售分类经手人提成方案
    updateProjectCategoryHandlerCommission: params => {
        return API.POST('api/saleProjectCategoryHandlerCommission/update', params)
    },
    // 获取分类下的项目提佣方案
    getProjectCommission: params => {
        return API.POST('api/saleProjectCommission/all', params)
    },
    // 保存项目销售提成方案
    updateProjectCommission: params => {
        return API.POST('api/saleProjectCommission/update', params)
    },
    // 获取项目下经手人提佣方案
    getProjectHanderCommission: params => {
        return API.POST('api/saleProjectHandlerCommission/all', params)
    },
    // 保存项目下经手人提成方案
    updateProjectHanderCommission: params => {
        return API.POST('api/saleProjectHandlerCommission/update', params)
    },
    //获取所有项目经手人提佣方案
    getProjectSchemeHanderCommission: params => {
        return API.POST('api/saleProjectSchemeHandlerCommission/all', params)
    },
    // 保存所有项目经手人提成方案
    updateProjectSchemeHanderCommission: params => {
        return API.POST('api/saleProjectSchemeHandlerCommission/update', params)
    },
    // 32.8.获取套餐卡项目销售分类经手人提佣方案
    getPackageSaleProjectCategoryHandlerCommission: params => {
        return API.POST('api/saleProjectCategoryHandlerCommission/packageCard', params)
    },
    // 32.13.获取套餐卡项目经手人提佣方案
    getPackageSaleProjectHandlerCommission: params => {
        return API.POST('api/saleProjectHandlerCommission/packageCard', params)
    },
    // 32.16.获取所有套餐卡项目经手人提佣方案
    getPackageSaleProjectSchemeHandlerCommission: params => {
        return API.POST('api/saleProjectSchemeHandlerCommission/packageCard', params)
    },
}